import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ApiFilterOperation, FormAutocomplete } from '@eas/common-web';
import { useAllUsers, useUsersAssignedList } from '@modules/users/user-api';
import { userLabelMapper } from '@utils/label-mapper';

export function UsersAssignDialog({
  primaryJournalId,
  partialJournalId,
}: {
  primaryJournalId: string | undefined;
  partialJournalId: string | undefined;
}) {
  // This hook returns all assigned users to a specific journal (primary or partial).
  const { result } = useUsersAssignedList({
    skip: false,
    primaryJournalId,
    partialJournalId,
  });

  const allUsersInAssignmentListIds = (result?.items ?? []).map(
    (p) => p.user?.id
  );

  const users = useAllUsers({
    filters: [
      {
        operation: ApiFilterOperation.NOT,
        filters: [
          {
            field: 'id',
            operation: ApiFilterOperation.IN,
            values: allUsersInAssignmentListIds,
          },
        ],
      },
    ],
  });

  return (
    <>
      <FormAutocomplete
        name="user"
        source={users}
        labelMapper={userLabelMapper}
        label={
          <FormattedMessage
            id="ESD__USER__COLLECTION__FIELD_LABEL__USER"
            defaultMessage="Používateľ"
          />
        }
        required
      />
    </>
  );
}
