import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import {
  DictionaryEvidence,
  EmptyComponent,
  Evidence,
  UserContext,
} from '@eas/common-web';
import { DictionaryDeleteAllActionBar } from '@components/dictionary-delete-all-actionbar/dictionary-delete-all-actionbar';
import { MultipleDeleteTableToolbarButton } from '@components/multiple-delete-table-toolbar-button/multiple-delete-table-toolbar-button';
import { Me, WorkGroup } from '@models';
import {
  EvidenceApiUrl,
  EvidenceBrowserUrl,
  ExportTag,
  Permission,
} from '@enums';
import { useEvidencePermission } from '@utils/permission-hook';
import { useColumns } from './work-group-columns';
import { Fields } from './work-group-fields';
import { useValidationSchema } from './work-group-schema';

export function WorkGroups() {
  const intl = useIntl();
  const { user } = useContext<UserContext<Me>>(UserContext);

  const validationSchema = useValidationSchema();

  const defaultWorkGroup: WorkGroup = {
    id: uuidv4(),
    name: '',
    members: [],
    tenant: user?.tenant,
  };

  const permissions = useEvidencePermission({
    options: {
      NEW: [Permission.WorkGroup.WORKGROUP_CREATE],
      EDIT: [Permission.WorkGroup.WORKGROUP_UPDATE],
      REMOVE: [Permission.WorkGroup.WORKGROUP_DELETE],
      REFRESH: [Permission.WorkGroup.WORKGROUP_DETAIL],
      SHARE: [Permission.WorkGroup.WORKGROUP_DETAIL],
      CHECK: [
        Permission.WorkGroup.WORKGROUP_CREATE,
        Permission.WorkGroup.WORKGROUP_UPDATE,
      ],
      SAVE: [
        Permission.WorkGroup.WORKGROUP_CREATE,
        Permission.WorkGroup.WORKGROUP_UPDATE,
      ],
      CLOSE: [
        Permission.WorkGroup.WORKGROUP_CREATE,
        Permission.WorkGroup.WORKGROUP_UPDATE,
      ],
    },
  });

  const evidence = DictionaryEvidence.useDictionaryEvidence<WorkGroup>({
    version: 3,
    identifier: 'WORK_GROUP',
    apiProps: {
      url: EvidenceApiUrl.WORK_GROUP,
    },
    tableProps: {
      columns: useColumns(),
      tableName: intl.formatMessage({
        id: 'ESD__WORK_GROUP__TABLE__TITLE',
        defaultMessage: 'Pracovné skupiny',
      }),
      reportTag: ExportTag.WORK_GROUP_GRID,
      showRefreshButton: false,
      toolbarProps: {
        after: (
          <>
            <MultipleDeleteTableToolbarButton
              deletePermissionKey={Permission.WorkGroup.WORKGROUP_DELETE}
            />
          </>
        ),
      },
    },
    detailProps: {
      initNewItem: () => defaultWorkGroup,
      FieldsComponent: Fields,
      validationSchema,
      GeneralFieldsComponent: EmptyComponent,
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ESD__WORK_GROUP__DETAIL__TITLE',
          defaultMessage: 'Správa pracovnej skupiny',
        }),
        disableBtn: (button) =>
          ['NEW', 'EDIT'].includes(button) &&
          (!user?.supplier || !user?.tenant),
        showBtn: permissions,
        ActionBar: () => (
          <DictionaryDeleteAllActionBar
            removeAllPermission={Permission.WorkGroup.WORKGROUP_DELETE}
            navigateBackUrl={EvidenceBrowserUrl.WORK_GROUP}
          />
        ),
      },
    },
  });

  return <Evidence {...evidence} />;
}
