import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  DatedEvidence,
  DetailContext,
  FormAutocomplete,
  FormCheckbox,
  FormPanel,
  FormTextArea,
  FormTextField,
  UserContext,
} from '@eas/common-web';
import { usePersons } from '@modules/person/person-api';
import { useRoles } from '@modules/roles/role-api';
import { useSuppliers } from '@modules/suppliers/supplier-api';
import { Redirect } from '@components/redirect/redirect';
import { EvidenceBrowserUrl, Permission } from '@enums';
import { personLabelMapper } from '@utils/label-mapper';

export function Fields() {
  const { hasPermission } = useContext(UserContext);
  const {
    source: { data },
  } = useContext(DetailContext);

  const roles = useRoles();
  const persons = usePersons();
  const suppliers = useSuppliers();

  const personId = data?.person?.id;
  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ESD__ROLES__DETAIL__GENERAL_PANEL_TITLE"
            defaultMessage="Základné informácie"
          />
        }
      >
        <FormTextField
          name="username"
          label={
            <FormattedMessage
              id="ESD__USERS__DETAIL__FIELD_LABEL__SURNAME"
              defaultMessage="Prihlasovacie meno"
            />
          }
          disabled
          required
        />

        <FormAutocomplete
          name="person"
          label={
            <FormattedMessage
              id="ESD__USERS__DETAIL__FIELD_LABEL__Person"
              defaultMessage="Osoba"
            />
          }
          source={persons}
          labelMapper={personLabelMapper}
          disabled
          required
          after={
            <>
              {hasPermission(Permission.Person.PERSON_DETAIL) && personId && (
                <Redirect url={EvidenceBrowserUrl.PERSON} id={personId} />
              )}
            </>
          }
        />
        <FormCheckbox
          name="person.internal"
          label={
            <FormattedMessage
              id="ESD__USERS__DETAIL__FIELD_LABEL__INTERNAL"
              defaultMessage="Interný"
            />
          }
          disabled
        />

        <FormAutocomplete
          name="person.supplier"
          label={
            <FormattedMessage
              id="ESD__USERS__DETAIL__FIELD_LABEL__ROLE"
              defaultMessage="Dodávateľ"
            />
          }
          source={suppliers}
          disabled
        />
        <FormAutocomplete
          name="role"
          label={
            <FormattedMessage
              id="ESD__USERS__DETAIL__FIELD_LABEL__ROLE"
              defaultMessage="Rola"
            />
          }
          source={roles}
          required
        />
        <FormTextArea
          name="userDetail"
          label={
            <FormattedMessage
              id="ESD__USERS__DETAIL__FIELD_LABEL__USER_DETAIL"
              defaultMessage="Detail používateľa"
            />
          }
        />
      </FormPanel>

      <DatedEvidence.DatedFields />
    </>
  );
}
