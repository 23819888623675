import {
  DomainObject,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { CollectionDialog } from '../collection-dialog';
import { CollectionToolbarAction } from '../collection-toolbar';

export function useReadCollectionDialog(getUrl: (id: string) => string) {
  const callApi = useEventCallback((id: string) => {
    return abortableFetch(`${getUrl(id)}`, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    });
  });

  return {
    callApi,
  };
}

export function resolveReadAction<T extends DomainObject>(
  props: Pick<
    CollectionToolbarAction<typeof useReadCollectionDialog, T>,
    'getUrl' | 'useApi'
  >
) {
  return {
    useApi: useReadCollectionDialog,
    ...props,
  };
}
