import React, { useContext } from 'react';
import {
  DetailContext,
  DetailHandle,
  DetailMode,
  EvidenceContext,
} from '@eas/common-web';
import { UsersField } from '@composite/users-collection/users-field';
import { PartialJournal } from '@models';
import { AssignmentType } from '@enums';
import { USERS_ASSSIGNMENT } from '../partial-journal-hooked-sources';

export function UsersAssignmentFields() {
  const {
    source: { data },
    mode,
    isLocked,
  } = useContext<DetailHandle<PartialJournal>>(DetailContext);

  const { hookedSources } = useContext(EvidenceContext);

  const userAssignments = hookedSources[USERS_ASSSIGNMENT]?.source;

  return (
    <>
      {data?.id && (
        <UsersField
          source={userAssignments}
          disabled={mode !== DetailMode.VIEW || isLocked}
          initialValues={{
            type: AssignmentType.PARTIAL_JOURNAL,
            journal: { id: data.id },
          }}
        />
      )}
    </>
  );
}
