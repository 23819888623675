import { compact } from 'lodash';
import React, { Ref, RefObject, useContext, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { AuthContext } from 'react-oauth2-code-pkce';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import {
  MenuItem,
  MenubarContext,
  NavigationContext,
  UserBtnAction,
  UserContext,
  useWebsocketEffect,
} from '@eas/common-web';
import { Me } from '@models';
import {
  AbsenceListPermissions,
  JournalEntryListPermissions,
  MechanismCategoryListPermissions,
  MechanismListPermissions,
  PartialJournalListPermissions,
  Permission,
  PrimaryJournalListPermissions,
  ProfessionListPermissions,
  TBPPListPermissions,
  TenantCode,
  WorkCategoryListPermissions,
  WorkListPermissions,
} from '@enums';
import { useUserContextSwitch } from '@utils/user-context-switch';
import { EvidenceBrowserUrl } from '../../enums/evidence-url';

export type ESDMenuItem = MenuItem & {
  permission?: string[];
  items?: ESDMenuItem[];
};

export function useMenuItems(menuRef: Ref<MenubarContext>) {
  const { navigate } = useContext(NavigationContext);
  const { user, hasPermission, reload, logout, logoutWithoutRedirect } =
    useContext<UserContext<Me>>(UserContext);
  const { logOut, idToken } = useContext(AuthContext);

  const {
    role,
    tenant: currentTenant,
    availableTenants = [],
    availableSuppliers = [],
  } = user ?? {};

  const isAdmin = role?.code === 'ROLE_ADMIN_ZSD_VSD' || 'ROLE_SUPER_ADMIN';

  const { handleSwitchTenant, handleSwitchSupplier } = useUserContextSwitch();

  useWebsocketEffect(() => {
    reload();
    // console.log('message SESSION_REFRESHED');
  }, ['SESSION_REFRESHED']);

  const items: ESDMenuItem[] = compact([
    // Hlavný denník
    {
      label: (
        <FormattedMessage
          id="ESD__MENU__PRIMARY_JOURNAL"
          defaultMessage="Hlavné denníky"
        />
      ),
      onClick: () => navigate(EvidenceBrowserUrl.PRIMARY_JOURNAL),
      permission: PrimaryJournalListPermissions,
      isActive: (pathname) => {
        return !![EvidenceBrowserUrl.PRIMARY_JOURNAL].find((url) =>
          pathname.includes(url)
        );
      },
      href: EvidenceBrowserUrl.PRIMARY_JOURNAL,
    },

    // Dielčí denník
    {
      label: (
        <FormattedMessage
          id="ESD__MENU__PARTIAL_JOURNAL"
          defaultMessage="Dielčie denníky"
        />
      ),
      onClick: () => navigate(EvidenceBrowserUrl.PARTIAL_JOURNAL),
      permission: PartialJournalListPermissions,
      isActive: (pathname) => {
        return !![EvidenceBrowserUrl.PARTIAL_JOURNAL].find((url) =>
          pathname.includes(url)
        );
      },
      href: EvidenceBrowserUrl.PARTIAL_JOURNAL,
    },

    // Denné záznamy
    {
      label: (
        <FormattedMessage
          id="ESD__MENU__JOURNAL_ENTRY"
          defaultMessage="Denné záznamy"
        />
      ),
      onClick: () => navigate(EvidenceBrowserUrl.JOURNAL_ENTRY),
      permission: JournalEntryListPermissions,
      isActive: (pathname) => {
        return !![EvidenceBrowserUrl.JOURNAL_ENTRY].find((url) =>
          pathname.includes(url)
        );
      },
      href: EvidenceBrowserUrl.JOURNAL_ENTRY,
    },

    // Osoby
    {
      label: <FormattedMessage id="ESD__MENU__PEOPLE" defaultMessage="Osoby" />,
      isActive: (pathname) => {
        return !![
          EvidenceBrowserUrl.USERS,
          EvidenceBrowserUrl.PERSON,
          EvidenceBrowserUrl.TENANTS,
        ].find((url) => pathname.includes(url));
      },
      items: [
        {
          label: (
            <FormattedMessage
              id="ESD__MENU__USERS"
              defaultMessage="Používatelia"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.USERS),
          href: EvidenceBrowserUrl.USERS,
          permission: [Permission.User.USER_LIST],
          icon: isAdmin && <LocalShippingIcon fontSize="small" />,
        },
        {
          label: (
            <FormattedMessage id="ESD__MENU__PERSON" defaultMessage="Osoby" />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.PERSON),
          href: EvidenceBrowserUrl.PERSON,
          permission: [Permission.Person.PERSON_LIST],
          icon: isAdmin && <LocalShippingIcon fontSize="small" />,
        },
        {
          label: (
            <FormattedMessage
              id="ESD__MENU__TENANTES"
              defaultMessage="Prehľad vlastníkov"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.TENANTS),
          href: EvidenceBrowserUrl.TENANTS,
          permission: [Permission.Tenant.TENANT_LIST],
        },
      ],
    },

    // Čiselníky
    {
      label: (
        <FormattedMessage
          id="ESD__MENU__DICTIONARIES"
          defaultMessage="Číselníky"
        />
      ),
      isActive: (pathname) => {
        return !![
          EvidenceBrowserUrl.MECHANISM_CATEGORY,
          EvidenceBrowserUrl.MECHANISMS,
          EvidenceBrowserUrl.WORK_CATEGORY,
          EvidenceBrowserUrl.WORK,
          EvidenceBrowserUrl.TBPP,
          EvidenceBrowserUrl.PROFESSION,
          EvidenceBrowserUrl.ABSENCE,
          EvidenceBrowserUrl.WORK_GROUP,
          EvidenceBrowserUrl.INVESTOR,
          EvidenceBrowserUrl.SUPPLIER,
        ].find((url) => pathname.includes(url));
      },
      items: [
        {
          label: (
            <FormattedMessage
              id="ESD__MENU__MECHANISM_CATEGORY"
              defaultMessage="Kategória mechanizmu"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.MECHANISM_CATEGORY),
          href: EvidenceBrowserUrl.MECHANISM_CATEGORY,
          permission: MechanismCategoryListPermissions,
          icon: isAdmin && <LocalShippingIcon fontSize="small" />,
        },
        {
          label: (
            <FormattedMessage
              id="ESD__MENU__MECHANISMS"
              defaultMessage="Mechanizmy"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.MECHANISMS),
          href: EvidenceBrowserUrl.MECHANISMS,
          permission: MechanismListPermissions,
          icon: isAdmin && <LocalShippingIcon fontSize="small" />,
        },
        {
          label: (
            <FormattedMessage
              id="ESD__MENU__WORK_CATEGORY"
              defaultMessage="Kategória prác"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.WORK_CATEGORY),
          href: EvidenceBrowserUrl.WORK_CATEGORY,
          permission: WorkCategoryListPermissions,
          icon: isAdmin && <LocalShippingIcon fontSize="small" />,
        },
        {
          label: (
            <FormattedMessage id="ESD__MENU__WORK" defaultMessage="Práca" />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.WORK),
          href: EvidenceBrowserUrl.WORK,
          permission: WorkListPermissions,
          icon: isAdmin && <LocalShippingIcon fontSize="small" />,
        },
        ...(user?.tenant?.code === TenantCode.VSD
          ? [
              {
                label: (
                  <FormattedMessage
                    id="ESD__MENU__TBPP"
                    defaultMessage="Práca - TBPP"
                  />
                ),
                onClick: () => navigate(EvidenceBrowserUrl.TBPP),
                href: EvidenceBrowserUrl.TBPP,
                permission: TBPPListPermissions,
                icon: isAdmin && <LocalShippingIcon fontSize="small" />,
              },
            ]
          : []),
        {
          label: (
            <FormattedMessage
              id="ESD__MENU__PROFESSION"
              defaultMessage="Zoznam pracovných pozícii"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.PROFESSION),
          href: EvidenceBrowserUrl.PROFESSION,
          permission: ProfessionListPermissions,
          icon: isAdmin && <LocalShippingIcon fontSize="small" />,
        },
        {
          label: (
            <FormattedMessage
              id="ESD__MENU__ABSENCES"
              defaultMessage="Druh neprítomností"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.ABSENCE),
          href: EvidenceBrowserUrl.ABSENCE,
          permission: AbsenceListPermissions,
          icon: isAdmin && <LocalShippingIcon fontSize="small" />,
        },
        {
          label: (
            <FormattedMessage
              id="ESD__MENU__WORK_GROUP"
              defaultMessage="Pracovné skupiny"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.WORK_GROUP),
          href: EvidenceBrowserUrl.WORK_GROUP,
          permission: [Permission.WorkGroup.WORKGROUP_LIST],
          icon: isAdmin && <LocalShippingIcon fontSize="small" />,
        },
        {
          label: (
            <FormattedMessage
              id="ESD__MENU__INVESTORS"
              defaultMessage="Investori"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.INVESTOR),
          href: EvidenceBrowserUrl.INVESTOR,
          permission: [Permission.Investor.INVESTOR_LIST],
        },
        // Dodávatelia
        {
          label: (
            <FormattedMessage
              id="ESD__MENU__SUPPLIERS"
              defaultMessage="Dodávatelia"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.SUPPLIER),
          href: EvidenceBrowserUrl.SUPPLIER,
          permission: [Permission.Supplier.SUPPLIER_LIST],
        },
      ],
    },
    {
      label: (
        <FormattedMessage
          id="ESD__MENU__SETTINGS"
          defaultMessage="Nastavenia"
        />
      ),
      isActive: (pathname) => {
        return !![
          EvidenceBrowserUrl.ROLES,
          EvidenceBrowserUrl.EXPORT_REQUESTS,
          EvidenceBrowserUrl.EXPORT_TEMPLATES,
          EvidenceBrowserUrl.ALOG,
          EvidenceBrowserUrl.SCHEDULE_JOBS,
          EvidenceBrowserUrl.SCHEDULE_RUNS,
        ].some((url) => pathname.includes(url));
      },
      items: [
        {
          label: (
            <FormattedMessage id="ESD__MENU__ROLES" defaultMessage="Role" />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.ROLES),
          permission: [Permission.Role.ROLE_LIST],
          href: EvidenceBrowserUrl.ROLES,
        },
        {
          label: (
            <FormattedMessage
              id="TAPIS__MENU__EXPORT_TEMPLATES"
              defaultMessage="Šablóny exportov"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.EXPORT_TEMPLATES),
          href: EvidenceBrowserUrl.EXPORT_TEMPLATES,
        },
        {
          label: (
            <FormattedMessage
              id="TAPIS__MENU__EXPORT_QUEUE"
              defaultMessage="Fronta exportov"
            />
          ),
          onClick: () => navigate(EvidenceBrowserUrl.EXPORT_REQUESTS),
          href: EvidenceBrowserUrl.EXPORT_REQUESTS,
        },
        {
          label: (
            <FormattedMessage
              id="ESD__MENU__AUDIT_ALOG"
              defaultMessage="Auditné logy"
            />
          ),
          permission: [Permission.Default.ALOG_ALL],
          onClick: () => navigate(EvidenceBrowserUrl.ALOG),
          href: EvidenceBrowserUrl.ALOG,
        },
        {
          label: (
            <FormattedMessage
              id="ESDą__MENU__DICTIONARIES__SCHEDULE_JOBS"
              defaultMessage="Naplánované časové úlohy"
            />
          ),
          permission: [Permission.Default.SCHEDULE_JOBS_ALL],
          onClick: () => navigate(EvidenceBrowserUrl.SCHEDULE_JOBS),
          href: EvidenceBrowserUrl.SCHEDULE_JOBS,
        },
        {
          label: (
            <FormattedMessage
              id="ESDą__MENU__DICTIONARIES__SCHEDULE_RUNS"
              defaultMessage="Naplánované časové behy"
            />
          ),
          permission: [Permission.Default.SCHEDULE_RUNS_ALL],
          onClick: () => navigate(EvidenceBrowserUrl.SCHEDULE_RUNS),
          href: EvidenceBrowserUrl.SCHEDULE_RUNS,
        },
        {
          label: (
            <FormattedMessage
              id="ESD__MENU__DICTIONARIES__SEQUENCES"
              defaultMessage="Číselné rady"
            />
          ),
          permission: [Permission.Default.SEQUENCE_ALL],
          onClick: () => navigate(EvidenceBrowserUrl.SEQUENCES),
          href: EvidenceBrowserUrl.SEQUENCES,
        },
      ],
    },
  ]);

  const userBtnActions: UserBtnAction[] = useMemo(
    () => [
      ...(hasPermission(Permission.Context.CONTEXT_SWITCH_TENANT)
        ? [
            {
              label: (
                <FormattedMessage
                  id="ESD__MENU__USER_TENANTS"
                  defaultMessage="Vlastníci"
                />
              ),
              subHeader: true,
            },
            ...availableTenants.map((tenant, index) => {
              const isCurrentTenant = tenant.id === currentTenant?.id;
              return {
                label: (
                  <FormattedMessage
                    id={`ESD__MENU__USER_TENANTS_${index}`}
                    defaultMessage={
                      isCurrentTenant
                        ? `Ukončiť zástup ${tenant.code}`
                        : tenant?.name
                    }
                  />
                ),
                action: () => {
                  const switchTenantAndSupplier = async () => {
                    await handleSwitchTenant(
                      isCurrentTenant ? undefined : tenant.id
                    );

                    const supplier = availableSuppliers.find(
                      (s) => s.code === tenant.code
                    );

                    if (supplier)
                      await handleSwitchSupplier(supplier.id, undefined, true);
                  };
                  //This is just for super admin role, because it does not come with default supplier. So we set it on tenant change,
                  switchTenantAndSupplier();
                },
              };
            }),
            {
              separator: true,
            },
          ]
        : []),
      {
        label: (
          <FormattedMessage
            id="ESD__MENU__USER_TENANTS"
            defaultMessage="Odhlásiť"
          />
        ),
        action: () => {
          localStorage.removeItem('CONFIG');

          if (process.env.NODE_ENV === 'development' && !idToken) {
            logout();
          } else {
            logoutWithoutRedirect();
            logOut();
          }
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentTenant, availableTenants, idToken]
  );

  useEffect(() => {
    if (menuRef) {
      const filteredItems = filterItems(items);

      (menuRef as RefObject<MenubarContext>)?.current?.modifyItems(
        () => filteredItems
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasPermission]);

  const filterItems = (items: ESDMenuItem[]) => {
    if (!user) {
      return [];
    }

    const getNodes = (result: ESDMenuItem[], item: ESDMenuItem) => {
      if (!item.items && !item.permission) {
        result.push(item);
        return result;
      }

      const permitted = (item?.permission ?? []).some((perm) => {
        return hasPermission(perm);
      });

      if (permitted) {
        result.push(item);
        return result;
      }

      if (Array.isArray(item.items)) {
        const items = item.items.reduce(getNodes, []);
        if (items.length) result.push({ ...item, items });
      }

      return result;
    };

    return items.reduce(getNodes, []);
  };

  const filteredItems = filterItems(items);

  return { items, userBtnActions, filteredItems };
}
