import { abortableFetch } from '@eas/common-web';

export const deleteMultipleGridItems = (apiUrl: string, selected: string[]) => {
  return abortableFetch(apiUrl, {
    method: 'DELETE',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify(selected),
  });
};
