import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  FormAutocomplete,
  FormInlineTableField,
  FormPanel,
  FormTextField,
} from '@eas/common-web';
import { useTenants } from '@modules/tenant/tenant-api';
import { useMembersColumns } from './work-group-columns';

export const Fields = () => {
  const membersColumns = useMembersColumns();
  const tenants = useTenants();

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ESD__WORK_GROUP__DETAIL__PANEL_TITLE__OVERVIEW"
            defaultMessage="Základné informácie"
          />
        }
      >
        <FormAutocomplete
          name="tenant"
          source={tenants}
          label={
            <FormattedMessage
              id="ESD__WORK_GROUP__DETAIL__FIELD_LABEL__TENANT"
              defaultMessage="Vlastník"
            />
          }
          disabled
          required
        />
        <FormTextField
          name="name"
          label={
            <FormattedMessage
              id="ESD__WORK_GROUP__DETAIL__FIELD_LABEL__NAME"
              defaultMessage="Názov pracovnej skupiny"
            />
          }
          required
        />
      </FormPanel>

      <FormPanel
        label={
          <FormattedMessage
            id="ESD__WORK_GROUP__DETAIL__PANEL_TITLE__MEMBERS"
            defaultMessage="Členovia"
          />
        }
      >
        <FormInlineTableField
          name="members"
          columns={membersColumns}
          labelOptions={{ hide: true }}
          layoutOptions={{ noSpacing: true }}
        />
      </FormPanel>
    </>
  );
};
