import React, { MutableRefObject, useContext } from 'react';
import MuiMenu from '@material-ui/core/Menu';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Autocomplete, UserContext } from '@eas/common-web';
import { useSuppliers } from '@modules/suppliers/supplier-api';
import { useUserContextSwitch } from '@utils/user-context-switch';

export const useStyles = makeStyles(() => ({
  menu: {
    height: 'fit-content',
    width: 500,
    overflow: 'hidden',
  },
}));

interface SupplierMenuProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  anchorRef: MutableRefObject<HTMLButtonElement | null>;
}

export function SupplierMenu({ open, setOpen, anchorRef }: SupplierMenuProps) {
  const { user } = useContext(UserContext);
  const { handleSwitchSupplier } = useUserContextSwitch();
  const suppliers = useSuppliers();

  const classes = useStyles();

  const closeMenu = () => {
    setOpen(false);
  };

  return (
    <MuiMenu
      open={open}
      getContentAnchorEl={null}
      anchorEl={anchorRef.current}
      onClose={closeMenu}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      MenuListProps={{
        disablePadding: true,
      }}
      PaperProps={{
        className: classes.menu,
      }}
    >
      <div>
        <Autocomplete
          source={suppliers}
          value={user.supplier}
          onChange={(val) => {
            if (val) {
              closeMenu();
              handleSwitchSupplier(val.id, undefined, true);
            }
          }}
        />
      </div>
    </MuiMenu>
  );
}
