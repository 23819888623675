import * as Yup from 'yup';
import {
  Address,
  Investor,
  PersonType,
  PrimaryJournal,
  Supplier,
} from '@models';

export function useValidationSchema() {
  return Yup.object<PrimaryJournal>().shape({
    construction: Yup.object().shape({
      name: Yup.string().nullable().required(),
      siteId: Yup.string().nullable().required(),
      type: Yup.mixed().nullable().required(),
    }),
    investor: Yup.mixed<Investor>().nullable().required(),
    supervisor: Yup.mixed<PersonType>().nullable().required(),
    leader: Yup.mixed<PersonType>().nullable().required(),
    contractor: Yup.mixed<Supplier>().nullable().required(),

    address: Yup.object<Address>().shape({
      region: Yup.string().nullable().required(),
      municipality: Yup.string().nullable().required(),
      coordinates: Yup.object()
        .shape({
          latitude: Yup.number()
            .nullable()
            .transform((value, originalValue) => {
              // Convert empty strings to null
              if (originalValue.trim() === '') return null;
              // Convert string to number
              const num = Number(originalValue);
              return isNaN(num) ? null : num; // Return null if not a valid number
            })
            .min(-90, 'Zemepisná šírka musí byť v rozmedzí -90 a 90')
            .max(90, 'Zemepisná šírka musí byť v rozmedzí -90 a 90'),
          longitude: Yup.number()
            .nullable()
            .transform((value, originalValue) => {
              // Convert empty strings to null
              if (originalValue.trim() === '') return null;
              // Convert string to number
              const num = Number(originalValue);
              return isNaN(num) ? null : num; // Return null if not a valid number
            })
            .min(-180, 'Zemepisná dĺžka musí byť v rozmedzí -180 až 180')
            .max(180, 'Zemepisná dĺžka musí byť v rozmedzí -180 až 180'),
        })
        .test(
          'both-or-neither',
          'Vyplňte obe polia súradníc alebo nechajte obe prázdne.',
          function (coordinates: any) {
            const { latitude, longitude } = coordinates;

            // Check if both are null or both are filled
            const isLatitudeFilled =
              latitude !== null && latitude !== undefined;
            const isLongitudeFilled =
              longitude !== null && longitude !== undefined;

            // Return true if both are filled or both are null
            const valid = isLatitudeFilled === isLongitudeFilled;

            if (!valid) {
              // Create an error for the "latitude" field specifically
              return this.createError({
                path: `address.coordinates.${
                  isLatitudeFilled ? 'longitude' : 'latitude'
                }`,
                message:
                  'Vyplňte obe polia súradníc alebo nechajte obe prázdne.',
              });
            }

            return valid;
          }
        ),
    }),
  });
}
