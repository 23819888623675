import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  AuthoredEvidence,
  DetailContext,
  DictionaryEvidence,
  FormPanel,
  FormTextField,
} from '@eas/common-web';
import { usePermission } from './role-api';
import { PermissionGroupFields } from './role-permission-group-fields';

export function Fields() {
  const { isExisting } = useContext(DetailContext);
  const permissionGroups = usePermission();

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ESD__ROLES__DETAIL__GENERAL_PANEL_TITLE"
            defaultMessage="Základné údaje"
          />
        }
      >
        <DictionaryEvidence.FieldName />
        <FormTextField
          name="code"
          label={
            <FormattedMessage
              id="ESD__ROLES__DETAIL__CODE"
              defaultMessage="Kód"
            />
          }
          disabled={isExisting}
        />
        <DictionaryEvidence.FieldOrder />
        <DictionaryEvidence.FieldActive />

        <AuthoredEvidence.AuthoredFields />
      </FormPanel>

      {permissionGroups && (
        <>
          {permissionGroups.map(({ id, description, permissions }) => (
            <PermissionGroupFields
              key={id}
              id={id}
              description={description}
              permissions={permissions}
            />
          ))}
        </>
      )}
    </>
  );
}
