import React, { ComponentPropsWithoutRef, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { regionApiErrors } from 'src/enums/messages/api-errors';
import { Filter, ScrollableSource, UserContext } from '@eas/common-web';
import { useMultipleDeleteApi } from '@components/collection-field/collection-api';
import { CollectionField2 } from '@components/collection-field/collection-field';
import { Me, WorkDone } from '@models';
import { EvidenceApiUrl, Permission } from '@enums';
import { WorkDoneCreateDialog } from './dialogs/work-done-create-dialog';
import { useColumns } from './work-done-columns';
import { useFilterFields } from './work-done-filter-fields';
import { useValidationSchema } from './work-done-schema';

interface WorkDoneFieldProps
  extends Partial<ComponentPropsWithoutRef<typeof CollectionField2<WorkDone>>> {
  refFilters?: Filter[];
  currentJournalId: string;
  source: ScrollableSource<WorkDone>;
}

export function WorkDoneField({
  currentJournalId,
  source,
  ...overrides
}: WorkDoneFieldProps) {
  const { hasPermission } = useContext<UserContext<Me>>(UserContext);

  const filters = useFilterFields();

  return (
    <CollectionField2
      title={
        <FormattedMessage
          id="ESD__FIELD_LABEL__WORK_DONE"
          defaultMessage="Vykonaná práca"
        />
      }
      source={source}
      useColumns={useColumns}
      disabled={false}
      createAction={{
        hide: !hasPermission(
          Permission.JournalEntryWorkDone.JOURNAL_ENTRY_WORK_DONE_CREATE
        ),
        getUrl: () => EvidenceApiUrl.JOURNAL_ENTRY + '/workdone',
        Dialog: WorkDoneCreateDialog,
        initValues: () => ({
          journalEntry: { id: currentJournalId },
        }),
        useSchema: useValidationSchema,
        dialogWidth: 600,
        label: 'Pridať',
      }}
      readAction={{
        getUrl: (collectionId) =>
          `${EvidenceApiUrl.JOURNAL_ENTRY}/workdone/${collectionId}`,
      }}
      updateAction={{
        hide: !hasPermission(
          Permission.JournalEntryWorkDone.JOURNAL_ENTRY_WORK_DONE_UPDATE
        ),
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.JOURNAL_ENTRY}/workdone/${collectionId}`,
        Dialog: WorkDoneCreateDialog,
        useSchema: useValidationSchema,
        dialogWidth: 600,
      }}
      deleteAction={{
        hide: !hasPermission(
          Permission.JournalEntryWorkDone.JOURNAL_ENTRY_WORK_DONE_DELETE
        ),
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.JOURNAL_ENTRY}/workdone/${collectionId}`,
        label: 'Odobrať',
        /* useApi: useMultipleDeleteApi, */
      }}
      assignAction={{
        hide: true,
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.JOURNAL_ENTRY}/workdone/${collectionId}`,
      }}
      unassignAction={{
        hide: true,
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.JOURNAL_ENTRY}/workdone/${collectionId}`,
      }}
      filters={filters}
      {...overrides}
    />
  );
}
