import { useContext } from 'react';
import {
  ApiFilterOperation,
  EvidenceStateAction,
  NavigationContext,
  Source,
  UserContext,
} from '@eas/common-web';
import { Me, Supplier } from '@models';
import { EvidenceBrowserUrl } from '@enums';

const useShowDetail = () => {
  const { navigate } = useContext(NavigationContext);
  const { user } = useContext<UserContext<Me>>(UserContext);

  // For this role we add default prefilter, so the user see only his supplier in list. And trigger showing only detail by setting the midpoint.
  const isSupplierAdmin =
    user?.person?.supplier?.code === 'ROLE_ADMIN_ZHOTOVITEL';

  const supplierId = user?.person?.supplier?.id;

  // midpoint === 0 means detail is fullscree
  const midPoint = isSupplierAdmin && supplierId ? 0 : undefined;

  const postProcess = async (data: Source<Supplier>) => {
    if (supplierId)
      navigate(EvidenceBrowserUrl.SUPPLIER, false, {
        data: supplierId,
        action: EvidenceStateAction.SHOW_ITEM,
      });
    return data;
  };

  const defaultPreFilters =
    isSupplierAdmin && supplierId
      ? [
          {
            operation: 'SUPPLIER' as ApiFilterOperation,
            field: 'id',
            value: supplierId,
          },
        ]
      : [];

  return {
    midPoint,
    postProcess,
    defaultPreFilters,
  };
};

export default useShowDetail;
