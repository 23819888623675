import React, { useContext } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Delete from '@material-ui/icons/Delete';
import {
  DetailActionButton,
  DetailContext,
  EvidenceContext,
  EvidenceStateAction,
  NavigationContext,
  PrimaryDetailActionbarButton,
} from '@eas/common-web';
import { DictionaryEvidence } from '@eas/common-web';
import { EvidenceBrowserUrl } from '@enums';
import { useDeleteAllDictionaryItems } from './dictionary-delete-all-api';

export const useStyles = makeStyles(() => ({
  toolbarIndentLeft: {
    marginLeft: 10,
  },
}));

type Props = {
  removeAllPermission: string;
  navigateBackUrl: EvidenceBrowserUrl;
};

export function DictionaryDeleteAllActionBar({
  removeAllPermission,
  navigateBackUrl,
}: Props) {
  const DictionaryActionbar = DictionaryEvidence.dictionaryActionbarFactory({});
  const { apiUrl } = useContext(EvidenceContext);
  const { setActive } = useContext(DetailContext);
  const { navigate } = useContext(NavigationContext);

  const { callApi, showButton } = useDeleteAllDictionaryItems(
    apiUrl + '/all',
    removeAllPermission
  );

  return (
    <>
      <DictionaryActionbar />

      {showButton && (
        <DetailActionButton
          promptKey={removeAllPermission}
          apiCall={callApi}
          buttonLabel={'Zmazať všetkých'}
          dialogTitle={'Varovanie'}
          dialogText={
            'Naozaj chcete zmazať všetky položky? Táto akcia je nevratná.'
          }
          ButtonComponent={PrimaryDetailActionbarButton}
          buttonProps={{
            startIcon: <Delete />,
          }}
          onSuccess={async () => {
            setActive(null);
            navigate(navigateBackUrl, true, {
              action: EvidenceStateAction.REFRESH_TABLE,
              data: null,
            });
          }}
        />
      )}
    </>
  );
}
