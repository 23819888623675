import React, { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { DetailContext, Tabs, TabsConfig, UserContext } from '@eas/common-web';
import { useFinalizeKepSignature } from '@components/kep-signature/kep-signature-api';
import { TreeStructureContext } from '@components/tree-structure/tree-structure-provider';
import { Me } from '@models';
import { JournalTypeEnum, Permission } from '@enums';
import { OverviewFields } from './fields/overview';
import { PartialJournalSignaturesFields } from './fields/signatures-fields';
import { UsersAssignmentFields } from './fields/user-assignment';

export function Fields() {
  const { hasPermission } = useContext<UserContext<Me>>(UserContext);

  const {
    source: { data },
  } = useContext(DetailContext);
  const { updateStructureInPartial } = useContext(TreeStructureContext);

  // Hook to finish KEP signature in app, after signing at the external signing service.
  useFinalizeKepSignature(JournalTypeEnum.PARTIAL);

  useEffect(() => {
    if (data?.id) {
      updateStructureInPartial(data.id);
    }
  }, [data?.id]);

  const config: TabsConfig = [
    {
      label: (
        <FormattedMessage
          id="ESD__PARTIAL_JOURNAL__TAB_TITLE__OVERVIEW"
          defaultMessage="Základné údaje"
        />
      ),
      key: 'OVERVIEW',
      validationKeys: [],
      content: <OverviewFields />,
    },
    ...(hasPermission(
      Permission.UserAssignmentPartialJournal
        .USER_ASSIGNMENT_PARTIAL_JOURNAL_LIST
    )
      ? [
          {
            label: (
              <FormattedMessage
                id="ESD__PARTIAL_JOURNAL__TAB_TITLE__PERSON_ASSIGNMENT"
                defaultMessage="Členovia"
              />
            ),
            key: 'PARTIAL_JOURNAL_PERSON_ASSIGNMENT',
            validationKeys: [],
            content: <UsersAssignmentFields />,
          },
        ]
      : []),
    {
      label: (
        <FormattedMessage
          id="ESD__PARTIAL_JOURNAL__TAB_TITLE__SIGNATURES"
          defaultMessage="Podpisy"
        />
      ),
      key: 'PARTIAL_JOURNAL_SIGNATURES',
      validationKeys: [],
      content: <PartialJournalSignaturesFields />,
    },
  ];
  return <Tabs config={config} />;
}
