import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import {
  AuthoredEvidence,
  DetailContext,
  EmptyComponent,
  Evidence,
  UserContext,
} from '@eas/common-web';
import { PersonType } from '@models';
import { EvidenceApiUrl, Permission } from '@enums';
import { useEvidencePermission } from '@utils/permission-hook';
import { useColumns } from './person-columns';
import { Fields } from './person-fields';
import { useValidationSchema } from './person-schema';

export function Person() {
  const { user } = useContext(UserContext);
  const id = uuidv4();
  const intl = useIntl();

  const validationSchema = useValidationSchema();

  const permissions = useEvidencePermission({
    options: {
      NEW: [Permission.Person.PERSON_CREATE],
      EDIT: [Permission.Person.PERSON_UPDATE],
      REMOVE: [Permission.Person.PERSON_DELETE],
      REFRESH: [Permission.Person.PERSON_DETAIL],
      SHARE: [Permission.Person.PERSON_DETAIL],
      CHECK: [Permission.Person.PERSON_CREATE, Permission.Person.PERSON_UPDATE],
      SAVE: [Permission.Person.PERSON_CREATE, Permission.Person.PERSON_UPDATE],
      CLOSE: [Permission.Person.PERSON_CREATE, Permission.Person.PERSON_UPDATE],
    },
  });

  const evidence = AuthoredEvidence.useAuthoredEvidence<PersonType>({
    version: 5,
    identifier: 'PERSON',
    apiProps: {
      url: EvidenceApiUrl.PERSON,
      /*  listItems: customListItemsFactory({
        preProcess: () => handleSwitchSupplier(),
      }), */
    },
    tableProps: {
      columns: useColumns(),
      tableName: intl.formatMessage({
        id: 'ESD__PERSON__TABLE__TITLE',
        defaultMessage: 'Osoby',
      }),
      showReportButton: false,
      showNamedSettingsButton: true,
    },
    detailProps: {
      FieldsComponent: Fields,
      GeneralFieldsComponent: EmptyComponent,
      validationSchema,
      initNewItem: () => ({
        id,
        tenant: user?.tenant,
        supplier: user?.supplier,
      }),
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ESD__PERSON__DETAIL__TITLE',
          defaultMessage: 'Osoby',
        }),
        showBtn: function useShowBtn(btn) {
          const {
            source: { data },
          } = useContext(DetailContext);

          // Removes Remove button if BE sends User to the detail
          // (it happens when the person has user attribute)
          if (btn === 'REMOVE' && !!data?.user) {
            return false;
          }

          return permissions(btn);
        },

        // ActionBar: DataSourceActionbar,
      },
    },
  });

  return <Evidence {...evidence} />;
}
