import React, { useContext, useRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import { Tooltip, UserContext } from '@eas/common-web';
import { EvidenceBrowserUrl } from '@enums';
import { SupplierMenu } from './supplier-menu';

export const useStyles = makeStyles((theme) => ({
  supplierIcon: {
    color: 'white',
    width: '20px',
    height: '20px',
    padding: 0,
    margin: 0,
    marginLeft: theme.spacing(1),
  },
  button: {
    height: 33,
    minWidth: 40,
    paddingLeft: 16,
    paddingRight: 16,
    fontSize: 12,
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: theme.palette.tertiary.light,
    },
    '&:disabled': {
      backgroundColor: 'transparent',
      color: 'lightgrey',
      '& .MuiButton-endIcon > *': {
        color: 'lightgrey !important',
      },
    },
  },
}));

const supplierEnabledEvidences = [
  EvidenceBrowserUrl.MECHANISM_CATEGORY,
  EvidenceBrowserUrl.MECHANISMS,

  EvidenceBrowserUrl.WORK_CATEGORY,
  EvidenceBrowserUrl.WORK,
  EvidenceBrowserUrl.TBPP,
  EvidenceBrowserUrl.WORK_GROUP,

  EvidenceBrowserUrl.ABSENCE,

  EvidenceBrowserUrl.PROFESSION,

  EvidenceBrowserUrl.PERSON,
  EvidenceBrowserUrl.USERS,
];

const shouldEnableSupplierButton = () => {
  const currentPath = window?.location?.pathname as EvidenceBrowserUrl;

  return (
    currentPath &&
    supplierEnabledEvidences.some((evidenceUrl) =>
      currentPath.startsWith(evidenceUrl)
    ) &&
    currentPath !== EvidenceBrowserUrl.HOME
  );
};

export function SupplierButton() {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const { user } = useContext(UserContext);

  const openMenu = () => {
    setOpen(true);
  };

  const isSupplierButtonEnabled = shouldEnableSupplierButton();

  const supplier = user?.supplier?.name;

  return (
    <>
      <Tooltip
        title={
          isSupplierButtonEnabled
            ? 'Zmeniť dodávateľa'
            : 'Zmena dodávateľa je umožnená iba pre číselníky'
        }
      >
        <span>
          <Button
            ref={anchorRef}
            variant="contained"
            color="primary"
            onClick={openMenu}
            disableElevation
            className={classes.button}
            endIcon={<LocalShippingIcon className={classes.supplierIcon} />}
            disabled={!isSupplierButtonEnabled}
          >
            {supplier}
          </Button>
        </span>
      </Tooltip>
      <SupplierMenu open={open} setOpen={setOpen} anchorRef={anchorRef} />
    </>
  );
}
