export const regionApiErrors = {
  PRIMARY_JOURNAL: {
    ENTITY_ALREADY_EXIST: 'Hlavný denník s rovnakým názvom už existuje',
  },
  PARTIAL_JOURNAL: {
    ENTITY_ALREADY_EXIST: 'Dielčí denník s rovnakým názvom už existuje',
  },
  MECHANISMS: {
    ENTITY_ALREADY_EXIST: 'Mechanizmus s rovnakou špz už existuje',
  },
  MECHANISM_CATEGORY: {
    ENTITY_ALREADY_EXIST: 'Kategória mechanizmu s rovnakým názvom už existuje',
  },
  WORK: {
    ENTITY_ALREADY_EXIST: 'Práca s rovnakým názvom už existuje',
  },
  WORK_CATEGORY: {
    ENTITY_ALREADY_EXIST: 'Kategória práce s rovnakým názvom už existuje',
  },
  WORK_TBPP: {
    ENTITY_ALREADY_EXIST: 'TBPP s rovnakým názvom už existuje',
  },
  PROFESSION: {
    ENTITY_ALREADY_EXIST: 'Pracovná pozícia s rovnakým názvom už existuje',
  },
  ABSENCE: {
    ENTITY_ALREADY_EXIST: 'Neprítomnosť s rovnakým názvom už existuje',
  },
};
