import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import {
  DictionaryEvidence,
  EmptyComponent,
  Evidence,
  UserContext,
  listItemsFactory,
} from '@eas/common-web';
import { MultipleDeleteTableToolbarButton } from '@components/multiple-delete-table-toolbar-button/multiple-delete-table-toolbar-button';
import { Me, Supplier } from '@models';
import { EvidenceApiUrl, Permission } from '@enums';
import { useEvidencePermission } from '@utils/permission-hook';
import { SupplierActionBar } from './supplier-actionbar';
import { useColumns } from './supplier-columns';
import { Fields } from './supplier-fields';
import { useValidationSchema } from './supplier-schema';
import useShowDetail from './use-show-detail';

export function Suppliers() {
  const intl = useIntl();

  const { user } = useContext<UserContext<Me>>(UserContext);

  const validationSchema = useValidationSchema();

  const { midPoint, postProcess, defaultPreFilters } = useShowDetail();

  const defaultSupplier: Supplier = {
    id: uuidv4(),
    tenant: user?.tenant,
    name: '',
  };

  const permissions = useEvidencePermission({
    options: {
      NEW: [Permission.Supplier.SUPPLIER_CREATE],
      EDIT: [Permission.Supplier.SUPPLIER_UPDATE],
      REMOVE: [Permission.Supplier.SUPPLIER_DELETE],
      REFRESH: [Permission.Supplier.SUPPLIER_DETAIL],
      SHARE: [Permission.Supplier.SUPPLIER_DETAIL],
      CHECK: [
        Permission.Supplier.SUPPLIER_CREATE,
        Permission.Supplier.SUPPLIER_UPDATE,
      ],
      SAVE: [
        Permission.Supplier.SUPPLIER_CREATE,
        Permission.Supplier.SUPPLIER_UPDATE,
      ],
      CLOSE: [
        Permission.Supplier.SUPPLIER_CREATE,
        Permission.Supplier.SUPPLIER_UPDATE,
      ],
    },
  });

  const evidence = DictionaryEvidence.useDictionaryEvidence<Supplier>({
    version: 4,
    identifier: 'SUPPLIER',
    switcherProps: { midPoint },
    apiProps: {
      url: EvidenceApiUrl.SUPPLIER,
      listItems: listItemsFactory({
        postProcess,
      }),
    },
    tableProps: {
      columns: useColumns(),
      tableName: intl.formatMessage({
        id: 'ESD__SUPPLIER__TABLE__TITLE',
        defaultMessage: 'Dodávatelia',
      }),
      showReportButton: false,
      showNamedSettingsButton: true,
      showBulkActionButton: false,
      defaultPreFilters,
      toolbarProps: {
        /*  after: (
          <MultipleDeleteTableToolbarButton
            deletePermissionKey={Permission.Supplier.SUPPLIER_DELETE}
          />
        ), */
      },
    },
    detailProps: {
      initNewItem: () => defaultSupplier,
      FieldsComponent: Fields,
      GeneralFieldsComponent: EmptyComponent,
      validationSchema,
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ESD__SUPPLIER__DETAIL__TITLE',
          defaultMessage: 'Dodávatelia',
        }),
        showBtn: permissions,
        disableBtn: (button) =>
          ['NEW', 'EDIT'].includes(button) && !user?.tenant,
        ActionBar: SupplierActionBar,
      },
    },
  });

  return <Evidence {...evidence} />;
}
