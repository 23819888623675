import React, {
  ComponentType,
  MutableRefObject,
  ReactElement,
  ReactNode,
  Ref,
  forwardRef,
  useContext,
  useEffect,
  useReducer,
  useRef,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';
import ButtonGroup from '@material-ui/core/ButtonGroup/ButtonGroup';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import PlusOneIcon from '@material-ui/icons/PlusOne';
import RemoveIcon from '@material-ui/icons/Remove';
import {
  ActionButtonHandle,
  Callback,
  DetailActionButton,
  DetailContext,
  DetailMode,
  DomainObject,
  EmptyComponent,
  FieldFilterType,
  Filter,
  RemoteTableFieldContext,
  SnackbarContext,
  SnackbarVariant,
  TableFieldToolbarButton,
  TableFieldToolbarButtons,
  TableFieldToolbarProps,
  useEventCallback,
} from '@eas/common-web';
import { ErrorObject } from '@models';
import { useStyles } from './collection-styles';
import {
  resolveAssignAction,
  useAssignCollectionDialog,
} from './dialog-actions/assign-collection-hook';
import {
  resolveCreateAction,
  useCreateCollectionDialog,
} from './dialog-actions/create-collection-hook';
import {
  resolveDeleteAction,
  useDeleteCollectionDialog,
} from './dialog-actions/delete-collection-hook';
import {
  resolveReadAction,
  useReadCollectionDialog,
} from './dialog-actions/read-collection-hook';
import { resolveUnassignAction } from './dialog-actions/unassign-collection-hook';
import {
  resolveUpdateAction,
  useUpdateCollectionDialog,
} from './dialog-actions/update-collection-hook';

export interface CollectionToolbarAction<
  API_FN extends (...args: any) => any,
  T
> {
  hide?: boolean;
  useApi?: API_FN;
  getUrl: Parameters<API_FN>[0];
  label?: ReactNode;
  title?: ReactNode;
  text?: ReactNode;
  initValues?: (current: T | null) => any;
  Dialog?: ComponentType<{
    onConfirm?: Callback;
    onCancel?: () => void;
  }>;
  dialogWidth?: number;
  useSchema?: Yup.Shape<any, any>;
  dialogShowConfirm?: boolean;
}

type CustomActionButton = ({
  id,
  selectedItems,
  disabled,
  handleError,
  selected,
}: {
  id: string;
  selectedItems: any[];
  selected: number[];
  disabled?: boolean;
  handleError: any;
}) => ReactNode;

export interface CollectionToolbarProps<T extends DomainObject> {
  disabled: boolean;

  initValuesRef: MutableRefObject<any>;

  createAction: CollectionToolbarAction<typeof useCreateCollectionDialog<T>, T>;
  readAction: Pick<
    CollectionToolbarAction<typeof useReadCollectionDialog, T>,
    'getUrl' | 'useApi' | 'Dialog'
  >;
  updateAction: CollectionToolbarAction<typeof useUpdateCollectionDialog<T>, T>;
  deleteAction: Pick<
    CollectionToolbarAction<typeof useDeleteCollectionDialog, T>,
    'hide' | 'getUrl' | 'useApi' | 'label' | 'title' | 'text'
  >;
  assignAction: CollectionToolbarAction<typeof useAssignCollectionDialog<T>, T>;
  unassignAction: CollectionToolbarAction<
    typeof useAssignCollectionDialog<T>,
    T
  >;

  // TODO: make it generic
  customActions?: CustomActionButton[];

  preFilters?: Filter[];
  filters?: FieldFilterType[];

  exceptions?: { [key: string]: string };

  staticItems?: T[];
}

export const CollectionToolbar = forwardRef(function CollectionToolbar<
  T extends DomainObject
>(
  {
    selectedIndexes,
    setSelectedIndexes,
    initValuesRef,

    disabled,
    preFilters,
    filters,

    staticItems,

    customActions,

    ...props
  }: TableFieldToolbarProps & CollectionToolbarProps<T>,
  ref: Ref<ActionButtonHandle>
) {
  const classes = useStyles();

  const id = useRef(uuidv4());

  const [, forceRender] = useReducer((x) => x + 1, 0);

  const createAction = resolveCreateAction<T>(props.createAction);
  const readAction = resolveReadAction(props.readAction);
  const updateAction = resolveUpdateAction<T>(props.updateAction);
  const deleteAction = resolveDeleteAction(
    props.deleteAction,
    selectedIndexes?.length ?? 0
  );
  const assignAction = resolveAssignAction<T>(props.assignAction);
  const unassignAction = resolveUnassignAction<T>(props.unassignAction);

  const { showSnackbar } = useContext(SnackbarContext);
  const { source: detailSource } = useContext(DetailContext);
  const { source: tableSource } = useContext(RemoteTableFieldContext);
  const items = staticItems ?? tableSource.items;

  const { callApi: readApiCall } = readAction.useApi(readAction.getUrl);

  const { callApi: createApiCall } = createAction.useApi(createAction.getUrl);
  const createValidationSchema = createAction.useSchema();

  const { callApi: updateApiCall } = updateAction.useApi(updateAction.getUrl);
  const updateValidationSchema = updateAction.useSchema();

  const { callApi: deleteApiCall } = deleteAction.useApi(deleteAction.getUrl);

  const { callApi: assignApiCall } = assignAction.useApi(assignAction.getUrl);
  const assignValidationSchema = assignAction.useSchema();

  const { callApi: unassignApiCall } = unassignAction.useApi(
    unassignAction.getUrl
  );
  const unassignValidationSchema = unassignAction.useSchema();

  // const selectedItem =
  //   selectedIndexes?.length === 1 ? items?.[selectedIndexes[0]] : {};
  const selectedItems = selectedIndexes?.map((i) => items?.[i]) ?? [];

  const selected = selectedIndexes ?? [];

  const ReadDialog = readAction?.Dialog;

  useEffect(() => {
    setSelectedIndexes([]);
  }, [detailSource]);

  const handleError = useEventCallback(async (err: ErrorObject<string>) => {
    const message = err.message
      ? ([err.message, SnackbarVariant.ERROR] as [ReactNode, SnackbarVariant])
      : props.exceptions?.[err.code ?? '']
      ? ([props.exceptions[err.code ?? ''], SnackbarVariant.ERROR] as [
          ReactNode,
          SnackbarVariant
        ])
      : ([
          <FormattedMessage
            id="ZSD__COLLECTIONS__DEFAULT_ERROR"
            defaultMessage="Chyba volania akcie"
            key={`ADD_COLLECTION_${id.current}`}
          />,
          SnackbarVariant.ERROR,
        ] as [ReactNode, SnackbarVariant]);

    showSnackbar(...message);
  });

  return (
    <div className={classes.tableActions}>
      <ButtonGroup className={classes.buttonGroup}>
        {ReadDialog && (
          <DetailActionButton
            promptKey={`SHOW_COLLECTION_${id.current}`}
            ref={ref}
            buttonLabel=""
            dialogTitle="Zobrazenie"
            dialogText=""
            dialogWidth={updateAction.dialogWidth}
            dialogDisableBackdrop={true}
            FormFields={() => <ReadDialog readApiCall={readApiCall} />}
            closeLabel="Zatvoriť"
            dialogShowConfirm={false}
            handleBeforeDialogOpen={async () => {
              const response = readApiCall(selectedItems?.[0]?.id);
              const json = await response.json();

              if (json) {
                initValuesRef.current = json;
              } /* else {
                initValuesRef.current = selected;
              } */

              forceRender();
            }}
            ButtonComponent={EmptyComponent}
            formInitialValues={initValuesRef.current}
            //onError={handleError}
          />
        )}
        {!createAction.hide && (
          <DetailActionButton
            promptKey={`ADD_COLLECTION_${id.current}`}
            apiCall={createApiCall}
            disabled={disabled}
            buttonLabel={createAction.label}
            dialogTitle={createAction.title}
            dialogText={createAction.text}
            dialogWidth={createAction.dialogWidth}
            dialogDisableBackdrop={true}
            ButtonComponent={({ label, onClick }) => (
              <TableFieldToolbarButton
                IconComponent={PlusOneIcon}
                show={true}
                disabled={disabled || selected.length > 0}
                title={label}
                onClick={onClick}
                color="primary"
                variant="contained"
              >
                <Typography variant="body2">{createAction.label}</Typography>
              </TableFieldToolbarButton>
            )}
            FormFields={createAction.Dialog}
            modes={[DetailMode.VIEW, DetailMode.EDIT]}
            formValidationSchema={createValidationSchema}
            formInitialValues={createAction.initValues(initValuesRef.current)}
            onError={handleError}
          />
        )}
        {!assignAction.hide && (
          <DetailActionButton
            promptKey={`ASSIGN_COLLECTION_${id.current}`}
            apiCall={(id, formData) =>
              assignApiCall(id, formData?.id, formData)
            }
            disabled={disabled}
            buttonLabel={assignAction.label}
            dialogTitle={assignAction.title}
            dialogText={assignAction.text}
            dialogWidth={assignAction.dialogWidth}
            dialogDisableBackdrop={true}
            ButtonComponent={({ label, onClick }) => (
              <TableFieldToolbarButton
                IconComponent={AddIcon}
                show={true}
                disabled={disabled || selected.length > 1}
                title={label}
                onClick={onClick}
                color="primary"
                variant="contained"
              >
                <Typography variant="body2">{assignAction.label}</Typography>
              </TableFieldToolbarButton>
            )}
            FormFields={assignAction.Dialog}
            modes={[DetailMode.VIEW, DetailMode.EDIT]}
            formValidationSchema={assignValidationSchema}
            onError={handleError}
          />
        )}
        {!updateAction.hide && (
          <DetailActionButton
            promptKey={`UPDATE_COLLECTION_${id.current}`}
            apiCall={(id, formData) =>
              updateApiCall(id, selectedItems[0].id, formData)
            }
            // ref={ref}
            buttonLabel={updateAction.label}
            dialogTitle={updateAction.title}
            dialogText={updateAction.text}
            dialogWidth={updateAction.dialogWidth}
            dialogDisableBackdrop={true}
            FormFields={updateAction.Dialog}
            dialogShowConfirm={updateAction.dialogShowConfirm}
            handleBeforeDialogOpen={async () => {
              const response = readApiCall(selectedItems[0].id);
              const json = await response.json();

              if (json) {
                initValuesRef.current = json;
              } else {
                initValuesRef.current = selectedItems[0];
              }

              forceRender();
            }}
            ButtonComponent={({ onClick, label }) => (
              <TableFieldToolbarButton
                IconComponent={EditIcon}
                show={true}
                disabled={disabled || selected.length !== 1}
                title={label}
                onClick={onClick}
                color="default"
              >
                <Typography variant="body2">{updateAction.label}</Typography>
              </TableFieldToolbarButton>
            )}
            modes={[DetailMode.VIEW, DetailMode.EDIT]}
            formInitialValues={initValuesRef.current}
            formValidationSchema={updateValidationSchema}
            onError={handleError}
          />
        )}
        {!unassignAction.hide && (
          <DetailActionButton
            promptKey={`UNASSIGN_COLLECTION_${id.current}`}
            apiCall={(id, formData) =>
              unassignApiCall(id, formData?.id, formData)
            }
            disabled={disabled}
            buttonLabel={unassignAction.label}
            dialogTitle={unassignAction.title}
            dialogText={unassignAction.text}
            dialogWidth={unassignAction.dialogWidth}
            dialogDisableBackdrop={true}
            handleBeforeDialogOpen={async () => {
              const response = readApiCall(selectedItems[0].id);
              const json = await response.json();

              if (json) {
                initValuesRef.current = json;
              } else {
                initValuesRef.current = selectedItems[0];
              }

              forceRender();
            }}
            ButtonComponent={({ label, onClick }) => (
              <TableFieldToolbarButton
                IconComponent={RemoveIcon}
                show={true}
                disabled={disabled || selected.length !== 1}
                title={label}
                onClick={onClick}
                color="secondary"
              >
                <Typography variant="body2">{unassignAction.label}</Typography>
              </TableFieldToolbarButton>
            )}
            FormFields={unassignAction.Dialog}
            modes={[DetailMode.VIEW, DetailMode.EDIT]}
            formInitialValues={unassignAction.initValues(initValuesRef.current)}
            formValidationSchema={unassignValidationSchema}
            onError={handleError}
          />
        )}
        {!deleteAction.hide && (
          <DetailActionButton
            promptKey={`DELETE_COLLECTION_${id.current}`}
            apiCall={(id) =>
              deleteApiCall(
                id,
                selectedItems.map((i) => i.id)
              )
            }
            buttonLabel={deleteAction.label}
            dialogTitle={deleteAction.title}
            dialogText={deleteAction.text}
            ButtonComponent={({ onClick, label }) => (
              <TableFieldToolbarButton
                IconComponent={DeleteIcon}
                show={true}
                disabled={disabled || selected.length === 0}
                title={label}
                onClick={onClick}
                color="secondary"
              >
                <Typography variant="body2">{deleteAction.label}</Typography>
              </TableFieldToolbarButton>
            )}
            modes={[DetailMode.VIEW, DetailMode.EDIT]}
            onError={handleError}
          />
        )}
        {customActions?.map((CustomAction) =>
          CustomAction({
            id: id.current,
            selectedItems,
            disabled,
            handleError,
            selected,
          })
        )}
      </ButtonGroup>
      {filters && (
        <ButtonGroup className={classes.buttonGroup}>
          <TableFieldToolbarButtons.FilterButton
            color="default"
            variant="text"
            source={tableSource}
            preFilters={preFilters}
            filtersFields={filters}
          />
        </ButtonGroup>
      )}
    </div>
  );
}) as <T extends DomainObject>(
  p: TableFieldToolbarProps &
    CollectionToolbarProps<T> & {
      ref: Ref<ActionButtonHandle>;
    }
) => ReactElement;
