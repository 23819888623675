import React, { ComponentPropsWithoutRef, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { regionApiErrors } from 'src/enums/messages/api-errors';
import { Filter, ScrollableSource, UserContext } from '@eas/common-web';

/* import { useMultipleDeleteApi } from '@components/collection-field/collection-api'; */
import { CollectionField2 } from '@components/collection-field/collection-field';
import { Me } from '@models';
import { EvidenceApiUrl, Permission } from '@enums';
import { MechanismCreateDialog } from './dialogs/mechanism-create-dialog';
import { useColumns } from './mechanism-columns';
import { useFilterFields } from './mechanism-filter-fields';
import { useValidationSchema } from './mechanism-schema';

interface MechanismFieldProps
  extends Partial<ComponentPropsWithoutRef<typeof CollectionField2<any>>> {
  refFilters?: Filter[];
  currentJournalId: string;
  source: ScrollableSource<any>;
}

export function MechanismField({
  currentJournalId,
  source,
  ...overrides
}: MechanismFieldProps) {
  const { hasPermission } = useContext<UserContext<Me>>(UserContext);

  const filters = useFilterFields();

  return (
    <CollectionField2
      title={
        <FormattedMessage
          id="ESD__FIELD_LABEL__MECHANISM"
          defaultMessage="Mechanizmy"
        />
      }
      // defaultExpanded={false}
      source={source}
      useColumns={useColumns}
      disabled={false}
      createAction={{
        hide: !hasPermission(
          Permission.JournalEntryMechanism.JOURNAL_ENTRY_MECHANISM_CREATE
        ),
        getUrl: () => EvidenceApiUrl.JOURNAL_ENTRY + '/mechanism',
        Dialog: MechanismCreateDialog,
        initValues: () => ({
          journalEntry: { id: currentJournalId },
          distance: 0,
          hours: 0,
        }),
        //  useSchema: useValidationSchema,
        dialogWidth: 650,
        label: 'Pridať',
      }}
      readAction={{
        getUrl: (collectionId) =>
          `${EvidenceApiUrl.JOURNAL_ENTRY}/mechanism/${collectionId}`,
      }}
      updateAction={{
        hide: !hasPermission(
          Permission.JournalEntryMechanism.JOURNAL_ENTRY_MECHANISM_UPDATE
        ),
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.JOURNAL_ENTRY}/mechanism/${collectionId}`,
        Dialog: MechanismCreateDialog,
        useSchema: useValidationSchema,
        dialogWidth: 650,
      }}
      deleteAction={{
        hide: !hasPermission(
          Permission.JournalEntryMechanism.JOURNAL_ENTRY_MECHANISM_DELETE
        ),
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.JOURNAL_ENTRY}/mechanism/${collectionId}`,
        /*   useApi: useMultipleDeleteApi, */
        label: 'Odobrať',
      }}
      assignAction={{
        hide: true,
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.JOURNAL_ENTRY}/mechanism/${collectionId}`,
      }}
      unassignAction={{
        hide: true,
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.JOURNAL_ENTRY}/mechanism/${collectionId}`,
      }}
      filters={filters}
      exceptions={regionApiErrors.MECHANISMS}
      {...overrides}
    />
  );
}
