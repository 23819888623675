import { useContext } from 'react';
import { EvidenceContext, UserContext, abortableFetch } from '@eas/common-web';
import { TenantCode } from '@enums';

const useTakeoverHook = () => {
  const { apiUrl } = useContext(EvidenceContext);
  const { user } = useContext(UserContext);

  const callApi = () => {
    return abortableFetch(`${apiUrl}/takeover`, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
    });
  };

  const showButton =
    user?.supplier &&
    user?.supplier?.code !== TenantCode.VSD &&
    user?.supplier?.code !== TenantCode.ZSD;

  return { callApi, showButton };
};

export default useTakeoverHook;
