import React from 'react';
import { useIntl } from 'react-intl';
import { DatedEvidence, EmptyComponent, Evidence } from '@eas/common-web';
import { User } from '@models';
import { EvidenceApiUrl, Permission } from '@enums';
import { useEvidencePermission } from '@utils/permission-hook';
import { useColumns } from './user-columns';
import { Fields } from './user-fields';

export function Users() {
  const intl = useIntl();

  const permissions = useEvidencePermission({
    options: {
      NEW: [],
      EDIT: [Permission.User.USER_UPDATE],
      REMOVE: [],
      REFRESH: [Permission.User.USER_DETAIL],
      SHARE: [Permission.User.USER_DETAIL],
      CHECK: [Permission.User.USER_UPDATE],
      SAVE: [Permission.User.USER_UPDATE],
      CLOSE: [Permission.User.USER_UPDATE],
    },
  });

  const evidence = DatedEvidence.useDatedEvidence<User>({
    version: 7,
    identifier: 'USERS',
    apiProps: {
      url: EvidenceApiUrl.USERS,
    },
    tableProps: {
      columns: useColumns(),
      tableName: intl.formatMessage({
        id: 'ESD__USERS__TABLE__TITLE',
        defaultMessage: 'Prehľad používateľov',
      }),
      showBulkActionButton: false,
      showReportButton: false,
      showNamedSettingsButton: true,
    },
    detailProps: {
      GeneralFieldsComponent: EmptyComponent,
      FieldsComponent: Fields,
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ESD__USERS__DETAIL__TITLE',
          defaultMessage: 'Detail používateľa',
        }),
        showBtn: permissions,
      },
    },
  });

  return <Evidence {...evidence} />;
}
