import React, { useContext } from 'react';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import {
  TableActionButton,
  TableActionButtonProps,
  UserContext,
} from '@eas/common-web';
import useTakeoverHook from './use-takeover-hook';

interface TakoverToolbarButtonProps extends Partial<TableActionButtonProps> {
  takeoverPermissionKey: string;
}

export function TakeoverTableToolbarButton({
  takeoverPermissionKey,
  ...overrides
}: TakoverToolbarButtonProps) {
  const { hasPermission } = useContext(UserContext);
  const { callApi, showButton } = useTakeoverHook();

  return (
    <>
      {hasPermission(takeoverPermissionKey) && showButton && (
        <TableActionButton
          promptKey={`TAKEOVER_${takeoverPermissionKey ?? ''}`}
          apiCall={callApi}
          buttonLabel={<RotateLeftIcon />}
          dialogTitle="Upozornenie"
          dialogText="Po potvrdení dôjde k prevzatiu číselníka vlastníka"
          confirmLabel="Potvrdiť"
          buttonTooltip="Prevziať"
          {...overrides}
        />
      )}
    </>
  );
}
