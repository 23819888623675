import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { regionApiErrors } from 'src/enums/messages/api-errors';
import { v4 as uuidv4 } from 'uuid';
import {
  DictionaryEvidence,
  EmptyComponent,
  Evidence,
  SnackbarContext,
  UserContext,
} from '@eas/common-web';
import { DictionaryDeleteAllActionBar } from '@components/dictionary-delete-all-actionbar/dictionary-delete-all-actionbar';
import { MultipleDeleteTableToolbarButton } from '@components/multiple-delete-table-toolbar-button/multiple-delete-table-toolbar-button';
import { TableToolbarImportButtons } from '@components/table-toolbar-import-buttons/table-toolbar-import-buttons';
import { TakeoverTableToolbarButton } from '@components/takeover-table-toolbar-button/takeover-table-toolbar-button';
import { Me, Work } from '@models';
import {
  EvidenceApiUrl,
  EvidenceBrowserUrl,
  ExportTag,
  Permission,
} from '@enums';
import { handleApiErrors } from '@utils/error-handler';
import { useEvidencePermission } from '@utils/permission-hook';
import { useColumns } from './work-columns';
import { Fields } from './work-fields';
import { useValidationSchema } from './work-schema';

export const Works = () => {
  const intl = useIntl();
  const { user } = useContext<UserContext<Me>>(UserContext);
  const { showSnackbar } = useContext(SnackbarContext);

  const validationSchema = useValidationSchema();

  const permissions = useEvidencePermission({
    options: {
      NEW: [Permission.Work.WORK_CREATE],
      EDIT: [Permission.Work.WORK_UPDATE],
      REMOVE: [Permission.Work.WORK_DELETE],
      REFRESH: [Permission.Work.WORK_DETAIL],
      SHARE: [Permission.Work.WORK_DETAIL],
      CHECK: [Permission.Work.WORK_CREATE, Permission.Work.WORK_UPDATE],
      SAVE: [Permission.Work.WORK_CREATE, Permission.Work.WORK_UPDATE],
      CLOSE: [Permission.Work.WORK_CREATE, Permission.Work.WORK_UPDATE],
    },
  });

  const defaultWork: Work = {
    id: uuidv4(),
    name: '',
    units: '',
    tenant: user?.tenant,
  };

  const evidence = DictionaryEvidence.useDictionaryEvidence<Work>({
    version: 6,
    identifier: 'WORK',
    apiProps: {
      url: EvidenceApiUrl.WORK,
      ...handleApiErrors(regionApiErrors.WORK, showSnackbar),
    },
    tableProps: {
      columns: useColumns(),
      tableName: intl.formatMessage({
        id: 'ESD__WORK__TABLE__TITLE',
        defaultMessage: 'Práca',
      }),
      reportTag: ExportTag.WORK_GRID,
      showRefreshButton: false,
      toolbarProps: {
        after: (
          <>
            <TableToolbarImportButtons
              importPermissionKey={Permission.Work.WORK_IMPORT}
            />
            <TakeoverTableToolbarButton
              takeoverPermissionKey={Permission.Work.WORK_TAKEOVER}
              dialogText="Prevezme aj kategórie prác"
            />
            <MultipleDeleteTableToolbarButton
              deletePermissionKey={Permission.Work.WORK_DELETE}
            />
          </>
        ),
      },
    },
    detailProps: {
      FieldsComponent: Fields,
      GeneralFieldsComponent: EmptyComponent,
      validationSchema,
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ESD__WORK__DETAIL__TITLE',
          defaultMessage: 'Správa práce',
        }),
        disableBtn: (button) =>
          ['NEW', 'EDIT'].includes(button) &&
          (!user?.supplier || !user?.tenant),
        showBtn: permissions,
        ActionBar: () => (
          <DictionaryDeleteAllActionBar
            removeAllPermission={Permission.Work.WORK_DELETE}
            navigateBackUrl={EvidenceBrowserUrl.WORK}
          />
        ),
      },
      initNewItem: () => defaultWork,
    },
  });

  return <Evidence {...evidence} />;
};
