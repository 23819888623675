import { stubFalse } from 'lodash';
import React, { ComponentType } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  EmptyComponent,
  FormPanel,
  FormTableField,
  TableFieldToolbarProps,
} from '@eas/common-web';
import { useSignaturesColumns } from './signatures.columns';

interface SignatureTableFieldProps {
  name?: string;
  ToolbarComponent?: ComponentType<TableFieldToolbarProps>;
  defaultExpanded?: boolean;
}

function SignaturesTableField({
  name = 'signatures',
  ToolbarComponent = EmptyComponent,
  defaultExpanded = true,
}: SignatureTableFieldProps) {
  const columns = useSignaturesColumns();

  return (
    <FormPanel
      label={
        <FormattedMessage
          id="ESD__SIGNATURES_TABLE__PANEL_TITLE__SIGNATURES"
          defaultMessage="Podpisy"
        />
      }
      defaultExpanded={defaultExpanded}
    >
      <FormTableField
        name={name}
        columns={columns}
        showDetailBtnCond={stubFalse}
        showRadioCond={stubFalse}
        labelOptions={{ hide: true }}
        layoutOptions={{ noSpacing: true }}
        maxRows={15}
        ToolbarComponent={ToolbarComponent}
        defaultSort={[
          {
            datakey: 'created',
            field: 'created',
            type: 'FIELD',
            order: 'ASC',
          },
        ]}
      />
    </FormPanel>
  );
}

export default SignaturesTableField;
