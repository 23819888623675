import React, {
  MouseEvent,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton/IconButton';
import MuiMenu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {
  ApiFilterOperation,
  DomainObject,
  InfiniteList,
  InfiniteListHandle,
  useEventCallback,
  useScrollableSource,
} from '@eas/common-web';
import { Notification as NotificationModel } from '@models';
import { EvidenceApiUrl } from '@enums';
import { useReadAll } from './actions/read-all-hook';
import { Notification } from './notification';

export const useStyles = makeStyles((theme) => ({
  header: {
    padding: '10px 16px',
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: `${theme.palette.primary.main}33`,
  },
  menu: {
    height: 'fit-content',
    width: 500,
    overflow: 'hidden',
  },
  loaderWrapper: {
    width: '100%',
    height: 'calc(100% - 50px)',
    position: 'absolute',
    backgroundColor: '#e1e2e340',
    zIndex: 1000,
  },
  loader: {
    position: 'absolute',
    left: 'calc(50% - 20px)',
    top: 'calc(50% - 20px)',
  },
}));

interface NotificationsMenuProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  anchorRef: MutableRefObject<HTMLButtonElement | null>;
  resetCount: () => void;
}

export function NotificationsMenu({
  open,
  setOpen,
  anchorRef,
  resetCount,
}: NotificationsMenuProps) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [includeRead, setIncludeRead] = useState<boolean>(false);
  const infiniteList = useRef<InfiniteListHandle<DomainObject>>(null);

  const source = useScrollableSource<NotificationModel>({
    url: `${EvidenceApiUrl.NOTIFICATION_RECEIVED}/list`,
    params: {
      filters: [
        { field: 'read', operation: ApiFilterOperation.EQ, value: false },
      ],
    },
  });

  const closeMenu = () => {
    setOpen(false);
  };

  const handleCloseSubmenu = () => {
    setAnchorEl(null);
  };

  const handleOpenSubmenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleReset = useEventCallback(() => {
    source.reset();
    resetCount();
    source.loadMore();
  });

  const { handleReadAll } = useReadAll({ refresh: handleReset });

  const handleShowAll = useEventCallback(() => {
    setIncludeRead((value) => !value);
    if (includeRead) {
      source.setParams({
        filters: [
          { field: 'read', operation: ApiFilterOperation.EQ, value: false },
        ],
      });
    } else {
      source.setParams({
        filters: [],
      });
    }

    source.reset();
    source.loadMore();
    setAnchorEl(null);
  });

  useEffect(() => {
    if (open) {
      source.reset();
      source.loadMore();
    }
  }, [open]);

  return (
    <MuiMenu
      open={open}
      getContentAnchorEl={null}
      anchorEl={anchorRef.current}
      onClose={closeMenu}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      MenuListProps={{
        disablePadding: true,
      }}
      PaperProps={{
        className: classes.menu,
      }}
    >
      <div style={{ height: source.loading ? 500 : 'fit-content' }}>
        <MuiMenu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseSubmenu}
        >
          <MenuItem onClick={handleShowAll}>
            {includeRead ? 'Skryť prečítané' : 'Zobraziť všetky'}
          </MenuItem>
          <MenuItem onClick={handleReadAll}>
            Označiť všetko ako prečítané
          </MenuItem>
        </MuiMenu>
        <Typography component="div" variant="body1" className={classes.header}>
          Notifikácie
          {!source.loading && <> ({source.count})</>}
          <IconButton size="small" onClick={handleOpenSubmenu}>
            <MoreVertIcon />
          </IconButton>
        </Typography>
        {source.loading && (
          <div className={classes.loaderWrapper}>
            <CircularProgress disableShrink className={classes.loader} />
          </div>
        )}
        {open && (
          <InfiniteList
            ref={infiniteList}
            source={source}
            maxListHeight={450}
            labelMapper={(item) => (
              <Notification
                item={item}
                refreshNotifications={handleReset}
                closeNotifications={closeMenu}
              />
            )}
          />
        )}
        {source.count === 0 && !source.loading && (
          <Typography variant="body1" style={{ padding: 10 }}>
            Všetko prečítané
          </Typography>
        )}
      </div>
    </MuiMenu>
  );
}
