/**
 * Region note permissions
 */
export enum PermissionJournalEntryNote {
  /**
   * Prehľad poznámok na dennom zázname
   */
  JOURNAL_ENTRY_NOTE_LIST = 'JOURNAL_ENTRY_NOTE_LIST',

  /**
   * Detail poznámky na dennom zázname
   */
  JOURNAL_ENTRY_NOTE_DETAIL = 'JOURNAL_ENTRY_NOTE_DETAIL',

  /**
   * Pridanie poznámky na denný záznam
   */
  JOURNAL_ENTRY_NOTE_CREATE = 'JOURNAL_ENTRY_NOTE_CREATE',

  /**
   * Editácia poznámky denného záznamu
   */
  JOURNAL_ENTRY_NOTE_UPDATE = 'JOURNAL_ENTRY_NOTE_UPDATE',

  /**
   * Zmazanie poznámky denného záznamu
   */
  JOURNAL_ENTRY_NOTE_DELETE = 'JOURNAL_ENTRY_NOTE_DELETE',
}
