import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import {
  EvidenceStateAction,
  NavigationContext,
  SnackbarContext,
  UserContext,
  useEventCallback,
} from '@eas/common-web';
import { Me } from '@models';
import { ContextSwitchSupplierType, Messages } from '@enums';
import { switchSupplier, switchTenant } from '../modules/users/user-api';

const getSwitchSupplierMessage = (switchType?: ContextSwitchSupplierType) => {
  switch (switchType) {
    case ContextSwitchSupplierType.SUPPLIER:
      return Messages.User.SUPPLIER_SWITCH_SUPPLIER;
    case ContextSwitchSupplierType.PARTIAL_JOURNAL:
      return Messages.User.PARTIAL_JOURNAL_SWITCH_SUPPLIER;
    case ContextSwitchSupplierType.PARTIAL_JOURNAL_ENTRY:
      return Messages.User.PARTIAL_JOURNAL_ENTRY_SWITCH_SUPPLIER;
    case ContextSwitchSupplierType.PRIMARY_JOURNAL_ENTRY:
      return Messages.User.PRIMARY_JOURNAL_ENTRY_SWITCH_SUPPLIER;
    default:
      return Messages.User.REMOVE_SUPPLIER;
  }
};

export const useUserContextSwitch = () => {
  const { showSnackbar } = useContext(SnackbarContext);
  const { user } = useContext<UserContext<Me>>(UserContext);
  const { navigate } = useContext(NavigationContext);
  const { pathname, state } = useLocation<any>();
  const handleSwitchSupplier = useEventCallback(
    async (
      supplierId?: string,
      switchType?: ContextSwitchSupplierType,
      refreshList?: boolean
    ) => {
      if (supplierId === user?.supplier?.id) {
        return;
      }

      try {
        await switchSupplier(supplierId).none();
        if (refreshList) {
          const id = state?.data;

          const newPath = id ? pathname.replace(`/${id}`, '') : pathname;

          navigate(newPath, undefined, {
            action: EvidenceStateAction.REFRESH_TABLE,
            data: null,
          });
        }

        // reload();
      } catch (e) {
        const message = getSwitchSupplierMessage(switchType);

        showSnackbar(...message.ERROR, false);
      }
    }
  );

  const handleSwitchTenant = useEventCallback(async (tenantId?: string) => {
    if (tenantId === user?.tenant?.id) {
      return;
    }

    const message = tenantId
      ? Messages.User.SUPPLIER_SWITCH_TENANT
      : Messages.User.SUPPLIER_REMOVE_TENANT;

    try {
      await switchTenant(tenantId).none();

      showSnackbar(...message.SUCCESS);
    } catch (e) {
      showSnackbar(...message.ERROR, false);
    }
  });

  return { handleSwitchSupplier, handleSwitchTenant };
};
