import {
  ApiFilterOperation,
  DomainObject,
  Filter,
  Params,
  abortableFetch,
  useAutocompleteSource,
  useEventCallback,
} from '@eas/common-web';

export function useCollectionSource<T extends DomainObject>(
  url: string,
  collectionKey: string,
  collectionId?: string,
  staticFilters?: Filter[]
) {
  const filters: Filter[] = [];

  if (staticFilters) {
    filters.push(...staticFilters);
  }

  if (collectionId) {
    filters.push({
      operation: ApiFilterOperation.NOT,
      filters: [
        {
          operation: ApiFilterOperation.EQ,
          field: collectionKey,
          value: collectionId,
        },
      ],
    });
  }

  const params: Params = {
    filters: [
      {
        operation: ApiFilterOperation.AND,
        filters,
      },
    ],
  };

  return useAutocompleteSource<T>({
    url: `${url}/autocomplete`,
    params,
  });
}

export const useMultipleDeleteApi = (
  getUrl: (id: string, collectionIds: string | string[]) => string
) => {
  const callApi = useEventCallback(
    (id: string, collectionIds: string | string[]) => {
      return abortableFetch(`${getUrl(id, collectionIds)}`, {
        method: 'DELETE',
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify(collectionIds),
      });
    }
  );

  return {
    callApi,
  };
};
