import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { regionApiErrors } from 'src/enums/messages/api-errors';
import { v4 as uuidv4 } from 'uuid';
import {
  DictionaryEvidence,
  EmptyComponent,
  Evidence,
  SnackbarContext,
  UserContext,
} from '@eas/common-web';
import { DictionaryDeleteAllActionBar } from '@components/dictionary-delete-all-actionbar/dictionary-delete-all-actionbar';
import { MultipleDeleteTableToolbarButton } from '@components/multiple-delete-table-toolbar-button/multiple-delete-table-toolbar-button';
import { TableToolbarImportButtons } from '@components/table-toolbar-import-buttons/table-toolbar-import-buttons';
import { Me, Mechanism } from '@models';
import { EvidenceApiUrl, ExportTag, Permission } from '@enums';
import { EvidenceBrowserUrl } from '@enums';
import { handleApiErrors } from '@utils/error-handler';
import { useEvidencePermission } from '@utils/permission-hook';
import { Fields } from './mechanism-fields';
import { useValidationSchema } from './mechanism-schema';
import { useColumns } from './mechanisms-columns';

export function Mechanisms() {
  const intl = useIntl();
  const { user } = useContext<UserContext<Me>>(UserContext);

  const validationSchema = useValidationSchema();

  const { showSnackbar } = useContext(SnackbarContext);

  const permissions = useEvidencePermission({
    options: {
      NEW: [Permission.Mechanism.MECHANISM_CREATE],
      EDIT: [Permission.Mechanism.MECHANISM_UPDATE],
      REMOVE: [Permission.Mechanism.MECHANISM_DELETE],
      REFRESH: [Permission.Mechanism.MECHANISM_DETAIL],
      SHARE: [Permission.Mechanism.MECHANISM_DETAIL],
      CHECK: [
        Permission.Mechanism.MECHANISM_CREATE,
        Permission.Mechanism.MECHANISM_UPDATE,
      ],
      SAVE: [
        Permission.Mechanism.MECHANISM_CREATE,
        Permission.Mechanism.MECHANISM_UPDATE,
      ],
      CLOSE: [
        Permission.Mechanism.MECHANISM_CREATE,
        Permission.Mechanism.MECHANISM_UPDATE,
      ],
    },
  });

  const defaultMechanism: Mechanism = {
    id: uuidv4(),
    name: '',
    tenant: user?.tenant,
  };

  const evidence = DictionaryEvidence.useDictionaryEvidence<Mechanism>({
    version: 6,
    identifier: 'MECHANISMS',
    apiProps: {
      url: EvidenceApiUrl.MECHANISMS,
      ...handleApiErrors(regionApiErrors.MECHANISMS, showSnackbar),
    },
    tableProps: {
      columns: useColumns(),
      tableName: intl.formatMessage({
        id: 'ESD__MECHANISMS__TABLE__TITLE',
        defaultMessage: 'Mechanizmy',
      }),
      reportTag: ExportTag.MECHANISM_GRID,
      toolbarProps: {
        after: (
          <>
            <TableToolbarImportButtons
              importPermissionKey={Permission.Mechanism.MECHANISM_IMPORT}
            />
            <MultipleDeleteTableToolbarButton
              deletePermissionKey={Permission.Mechanism.MECHANISM_DELETE}
            />
          </>
        ),
      },
      showRefreshButton: false,
    },
    detailProps: {
      FieldsComponent: Fields,
      GeneralFieldsComponent: EmptyComponent,
      validationSchema,
      toolbarProps: {
        title: 'Mechanizmy',
        /*    subtitle: `Mechanismy${
          user?.supplier ? ' - ' + user.supplier.name : ''
        }`, */
        disableBtn: (button) =>
          ['NEW', 'EDIT'].includes(button) &&
          (!user?.supplier || !user?.tenant),
        showBtn: permissions,
        ActionBar: () => (
          <DictionaryDeleteAllActionBar
            removeAllPermission={Permission.Mechanism.MECHANISM_DELETE}
            navigateBackUrl={EvidenceBrowserUrl.MECHANISMS}
          />
        ),
      },
      initNewItem: () => defaultMechanism,
    },
  });

  return <Evidence {...evidence} />;
}
